@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;

}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track{
  background: #E0E0E0;
}

/*Handle*/
::-webkit-scrollbar-thumb{
  background: #888;
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: #555;
}
.pro-sidebar {
  transition: width 0.3s;
}